<script>
import RegionsForm from './RegionsForm'

export default {
  extends: RegionsForm,

  created() {
    this.getRegion(this.$route.params.id).then(response => {
      this.form = response
    })
  },

  methods: {
    onSubmit() {
      this.updateRegion(this.form.id, this.form)
        .then(() => {
          this.successSubmit({ message: 'Регион успешно изменен', routeName: 'regions' })
        })
        .finally(() => { this.afterSubmit() })
    }
  }
}
</script>
